import c3api from "@/c3api";
import { ref } from "vue";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

export default function useSpecialOrders() {
  const specialOrderDocumentsUploading = ref(false);
  const specialOrderLoading = ref(false);
  const specialOrder = ref([]);
  const specialOrderDocuments = ref([]);
  const toast = useToast();
  const router = useRouter()


  const fetchSpecialOrder = async (id) => {
    try {
      specialOrderLoading.value = true;
      const response = await c3api.get(`/special_orders/${id}`);
      return (specialOrder.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      specialOrderLoading.value = false;
    }
  };

  const fetchSpecialOrderItems = async (id) => {
    try {
      specialOrderLoading.value = true;
      const response = await c3api.get(`/special_orders/${id}/items`);
      return (response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      specialOrderLoading.value = false;
    }
  };

  const fetchSpecialHistory = async (id) => {
    try {
      specialOrderLoading.value = true;
      const response = await c3api.get(`/special_orders/${id}/history`);
      return (response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      specialOrderLoading.value = false;
    }
  };

  const fetchSpecialOrderDocuments = async (id) => {
    try {
      specialOrderLoading.value = true;
      const response = await c3api.get(`/special_orders/${id}/order_documents`);
      return (specialOrderDocuments.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      specialOrderLoading.value = false;
    }
  };

  const uploadSpecialOrderDocuments = async (id, formData) => {
    try {
      specialOrderDocumentsUploading.value = true;
      const response = await c3api.post(`/special_orders/${id}/order_documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("File uploaded successfully.");
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
      specialOrderDocumentsUploading.value = false;
    } finally {
      specialOrderDocumentsUploading.value = false;
    }
  }
  
  const addSpecialOrder = async (data) => {
    try {
      specialOrderLoading.value++;
      const response = await c3api.post("/special_orders", data);
      toast.success("Special Order Added Successfully.");
      router.push("/special-orders");
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      specialOrderLoading.value--;
    }
  };

  const fetchSpecialOrderBillableActivities = async (id) => {
    try {
      specialOrderLoading.value = true;
      const response = await c3api.get(`/special_orders/${id}/billable_activity`);
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      specialOrderLoading.value = false;
    }
  };

  return {
    specialOrderLoading,
    specialOrder,
    addSpecialOrder,
    fetchSpecialOrder,
    fetchSpecialOrderItems,
    fetchSpecialHistory,
    specialOrderDocuments,
    fetchSpecialOrderDocuments,
    specialOrderDocumentsUploading,
    uploadSpecialOrderDocuments,
    fetchSpecialOrderBillableActivities,
  };
}
